function tableruler() {
    if (document.getElementById && document.createTextNode) {
        var highlightRows = document.querySelectorAll('table.listing tr:not(.header):not(.highlight):not(.altRowselected):not(.selected):not(.note):not(.edit)');

        for (var i = 0; i < highlightRows.length; i++) {
            highlightRows[i].onmouseover = function () { this.classList.add('hover'); };
            highlightRows[i].onmouseout = function () { this.classList.remove('hover'); };
        }
    }
}

function RedirectToMonthlySales() {
    var dropdownList = document.getElementsByClassName("ddlPeriod");
    if (dropdownList != undefined && dropdownList.length > 0) {
        var value = dropdownList[0].options[dropdownList[0].selectedIndex].value;
        window.location = "/admin/viewSales.aspx?SystemFundPeriodID=" + value;
    }
}
function popAddWindow(url)
{
	var blnIsOpen;
	var strQueryString;
	var helpWindow; 

	strQueryString = window.location.href;
	if(strQueryString.indexOf('?') != -1)
	{
		strQueryString = strQueryString.substring(strQueryString.indexOf('?'), strQueryString.length);
	}
	else
	{
		strQueryString = '';
	}
	if(helpWindow)
	{
		if(!helpWindow.closed)
		{
			blnIsOpen = true;
		}
		else
		{
			blnIsOpen = false;
		}
	}
	else
	{
		blnIsOpen = false;
	}

	if(!blnIsOpen)
	{
		//var helpHPosition = screen.availWidth - 390
		//window.resizeTo(screen.availWidth,screen.availHeight);
		//window.moveTo(0,0);
		//window.resizeBy(-390,0);
	    helpWindow = window.open(url, 'helpwindow', 'width=950,height=' + 800 + ',toolbars=no,resizable=yes,scrollbars=yes,top=50');
		helpWindow.focus();

	}
	else{

	    helpWindow.open(url, 'helpwindow', 'width=950,height=' + 800 + ',toolbars=no,resizable=yes,scrollbars=yes,top=50');
		helpWindow.focus();
	}
}

function moveSelectedOptions(from,to) {
	// Move them over
	for (var i=0; i<from.options.length; i++) {
		var o = from.options[i];
		if (o.selected) {
			to.options[to.options.length] = new Option( o.text, o.value, false, false);
			}
		}
	// Delete them from original
	for (var i=(from.options.length-1); i>=0; i--) {
		var o = from.options[i];
		if (o.selected) {
			from.options[i] = null;
			}
		}
		from.selectedIndex = -1;
		to.selectedIndex = -1;
	}

	//functions for the select box moving
	function selectAllOptions(obj) {
	for (var i=0; i<obj.options.length; i++) {
		obj.options[i].selected = true;
		}
	}

	function closeWindow()
	{
		window.close();
	}

	function closeAddWindow() {
	    var random = Math.floor(Date.now());
	    var queryString = setQueryStringParameter(window.opener.location.search, 'reload', random);

	    window.opener.location = window.opener.location.origin + window.opener.location.pathname + queryString + window.opener.location.hash;

	    window.close();
	}

function checkDelete(url) {
if (confirm("Are you sure you want to delete this record?"))
	window.location.href = url;
}

function checkDeleteConfirmRelationshipRemoval(url) {
if (confirm("Are you sure you want to delete this record and have you removed all related data?"))
	window.location.href = url;
}

function confirmSubmit(url)  {
confirm("By clicking OK, you will submit this report for this period as final.")
}



/*
Javascript Browser Sniff 1.0
Jim Cummins - http://www.conxiondesigns.com
*/
var isIE = false;
var isOther = false;
var isNS4 = false;
var isNS6 = false;
if(document.getElementById)
{
	if(!document.all)
	{
		isNS6=true;
	}
	if(document.all)
	{
		isIE=true;
	}
}
else
{
	if(document.layers)
	{
		isNS4=true;
	}
	else
	{
		isOther=true;
	}
}

function disableEnter()
{

  return !(window.event && window.event.keyCode == 13);


}

/*
End of Browser Sniff 1.0
*/

/*
Access Layer Style Properties
Jim Cummins - http://www.conxiondesigns.com
Required components:  Javascript Browser Sniff 1.0
*/
function aLs(layerID)
{
var returnLayer;
	if(isIE)
	{
		returnLayer = eval("document.all." + layerID + ".style");
	}
	if(isNS6)
	{
		returnLayer = eval("document.getElementById('" + layerID + "').style");
	}
	if(isNS4)
	{
		returnLayer = eval("document." + layerID);
	}
	if(isOther)
	{
		returnLayer = "null";
		alert("-[Error]-\nDue to your browser you will probably not\nbe able to view all of the following page\nas it was designed to be viewed. We regret\nthis error sincerely.");
	}
return returnLayer;
}
/*
End of Accessing Layer Style Properties
*/

function Hide(ID)
{
	aLs(ID).visibility = "hidden";
}

function Show(ID)
{
	aLs(ID).visibility = "visible";
}

function goToAnchor(where) {
    if (where) {
        window.location.hash = where;
    }
}


function popup_print(page) {
    window.open(page, "popup", "toolbar=no,scrollbars=yes,width=950,height=800,resizable=yes");
}

function getQueryStringParameter(queryString, parameterName) {
    var parameters = getQueryStringArray(queryString);

    var match = parameters.find(function (parameter) {
        return parameter.name == parameterName;
    });

    if (match) {
        return match.value;
    }
}

function setQueryStringParameter(queryString, parameterName, parameterValue) {
    var parameters = getQueryStringArray(queryString);
    var found = false;

    for (var i = 0; i < parameters.length; i++) {
        if (parameters[i].name == parameterName) {
            parameters[i].value = parameterValue;
            found = true;
            break;
        }
    }

    if (!found) {
        parameters.push({ name: parameterName, value: parameterValue });
    }

    return '?' + parameters.map(function (value) { return value.name + '=' + encodeURIComponent(value.value) }).join('&');
}

function getQueryStringArray(queryString) {
    if (queryString) {
        if (queryString.indexOf('?') === 0) {
            queryString = queryString.slice(1);
        }

        return queryString.split('&').map(function (value) {
            var parts = value.split('=');

            if (parts.length === 2) {
                return { name: parts[0], value: decodeURIComponent(parts[1]) };
            }
            else if (parts.length === 1) {
                return { name: parts[0], value: undefined };
            }
        });
    } else {
        return [];
    }
}

window.addEventListener("load", function load(event) {
    window.removeEventListener("load", load, false); //remove listener, no longer needed
    startSessionEndMonitor();
}, false);

function startSessionEndMonitor() {
    var session = getSessionEndTime();
    var alerted = false;

    // If the session end time cookie has not been set, then do nothing.
    if (session === undefined) {
        return;
    }

    // Start the 1 second timer to check the session expire.
    var sessionTimer = setInterval(function() {
        var endTime = getSessionEndTime();

        // The session end time cookie may expire before we get the chance to
        //  check it one last time. If it does expire we will just assume the
        //  session expired.
        if (endTime === undefined) {
            clearInterval(sessionTimer);
            showOverlay();
            hideSessionEndingMessage();
            showSessionEndedMessage();
            return;
        }

        var diff = endTime.getTime() - Date.now();

        // Again, the session end time cookie may expire before we get the
        //  chance to check it one last time. For that reason, we will start
        //  showing the warning at 31 seconds and show session expire at 1 second.
        if (diff <= 121000 && diff > 1000) {
            if (!alerted) {
                alerted = true;
                showOverlay();
                showSessionEndingMessage();
            }

            var secondsLeft = Math.ceil(diff / 1000) - 1;
            var timeRemainingSpan = document.getElementsByClassName('session-time-remaining');

            if (timeRemainingSpan.length === 1) {
                timeRemainingSpan[0].innerText = secondsLeft;
            }
        } else if (diff <= 1000) {
            clearInterval(sessionTimer);
            showOverlay();
            hideSessionEndingMessage();
            showSessionEndedMessage();
            deleteSessionEndTime();
        } else {
            if (alerted) {
                alerted = false;
                hideSessionEndingMessage();
                hideOverlay();
            }
        }
    }, 1000);
}

function showOverlay() {
    var overlay = document.getElementsByClassName('overlay');

    if (overlay.length === 0) {
        var overlayDiv = document.createElement('div');
        overlayDiv.className = 'overlay';
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(overlayDiv);
    }
}

function hideOverlay() {
    var overlay = document.getElementsByClassName('overlay');

    if (overlay.length > 0) {
        for (var i = 0; i < overlay.length; i++) {
            overlay[i].parentElement.removeChild(overlay[i]);
        }
    }
}

function showSessionEndingMessage() {
    var overlayDiv = document.createElement('div');
    overlayDiv.className = 'overlay-message';
    overlayDiv.innerHTML = '<div><p>Your session will expire in <span class="session-time-remaining">120</span> seconds.<br />Click OK to continue your working session; ignore to let session expire.</p></div><input type="button" value="OK" class="button overlay-message-button">';
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(overlayDiv);

    var button = document.querySelector('.overlay-message .button');

    if (button) {
        button.addEventListener('click', function () {
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {

                    hideSessionEndingMessage();
                    hideOverlay();
                }
            };
            xhttp.open("GET", "/sessionExtend.aspx", true);
            xhttp.send();
        });
    }
}

function hideSessionEndingMessage() {
    var overlay = document.getElementsByClassName('overlay-message');

    if (overlay.length > 0) {
        for (var i = 0; i < overlay.length; i++) {
            overlay[i].parentElement.removeChild(overlay[i]);
        }
    }
}

function showSessionEndedMessage() {
    var overlayDiv = document.createElement('div');
    overlayDiv.className = 'overlay-message';
    overlayDiv.innerHTML = '<div><p>Your session has expired.<br />Click OK to log back in.</p></div><input type="button" value="OK" class="button overlay-message-button">';
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(overlayDiv);

    var button = document.querySelector('.overlay-message .button');

    if (button) {
        button.addEventListener('click', function() {
            window.location.href = '/sessionend.aspx';
        });
    }
}

function getSessionEndTime() {
    var cookie = readCookie('sessionEndTime');

    if (cookie) {
        return new Date(cookie);
    }
}

function deleteSessionEndTime() {
    deleteCookie('sessionEndTime', '/');
}

function readCookie(name) {
    var cookies = document.cookie.split('; ');

    for (var i = 0; i < cookies.length; i++) {
        var nameValue = cookies[i].split('=');

        if ((nameValue.length === 2) && (nameValue[0] === name)) {
            return nameValue[1];
        }
    }
}

function writeCookie(name, value, expires, path) {
    var cookie = name + '=' + value + ';';

    if (expires) {
        cookie += ' expires=' + expires.toGMTString() + ';';
    }

    if (path) {
        cookie += ' path=' + path + ';';
    }

    document.cookie = cookie;
}

function deleteCookie(name, path) {
    writeCookie(name, '', new Date(Date.now() - 86400000), path);
}

